import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

// const PRIMARY = {
//   lighter: '#D1E9FC',
//   light: '#76B0F1',
//   main: '#2065D1',
//   dark: '#103996',
//   darker: '#061B64',
//   contrastText: '#fff',
// };
const PRIMARY = {
  lighter: "#FFF6D2",
  light: "#FFDC79",
  main: "#FFB620",
  dark: "#B77510",
  darker: "#7A4406",
  contrastText: "#fff",
};
// const PRIMARY = {
//   lighter: '#FFEED8',
//   light: '#FF9C81',
//   main: '#FF382D',
//   dark: '#5E8CF2',
//   darker: '#7A0F0B',
//   contrastText: '#fff',
// };

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E2FBDD",
  light: "#EAF8EE",
  main: "#2ECC71",
  dark: "#25844A",
  darker: "#0E5839",
  contrastText: "#FFFFFF",
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FAF8EB",
  main: "#C1AD2E",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: "#E1E1E1",
  text: {
    primary: PRIMARY.main,
    primary2: "#FFFFFF99",
    secondary: "#B0B0B0",
    disabled: "#B0B0B0",
  },
  background: { paper: "#1A1A1A", default: "#F7F7F7", neutral: "#313131" },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: "#B0B0B0",
    disabledBackground: GREY[500_24],
    disabledIcon: "#B0B0B0",
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  numbers: [
    "#1FB02A",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
    "#FFFFFF",
    "#FF2727",
  ],
};

export default palette;
