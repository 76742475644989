import {
  Box,
  Divider,
  Drawer,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import CustomIcon from "../mini-components/CustomIcon";
import CustomScroller from "../mini-components/CustomScroller";
import { GameDataContext } from "../services/GameCommunication";
import palette from "../theme/palette";

export default function BetHistory() {
  const { betHistory } = useContext(GameDataContext);
  const [groupedBets, setGroupedBets] = useState({});

  const [selectedBet, setSelectedBet] = useState(null);

  useEffect(() => {
    console.log(betHistory, "dfjdsfjdfhbj");
    if (betHistory && betHistory.length) {
      // betHistory.map((e)=>{
      //     console.log(moment.unix(e.date).format("DD/MM/YYYY"))
      // })
      let groupByDate = betHistory.reduce((res, arr) => {
        res[moment.unix(arr.end_time).format("DD/MM/YYYY")] =
          res[moment.unix(arr.end_time).format("DD/MM/YYYY")] || [];
        res[moment.unix(arr.end_time).format("DD/MM/YYYY")].push(arr);
        return res;
      }, {});
      console.log(groupByDate);

      setGroupedBets(groupByDate);
    }
  }, [betHistory]);

  return (
    <Box
      mt={2}
      mx={-3}
      width={"calc(100% + 48px)"}
      height={"100%"}
      sx={{ overflow: "auto" }}
    >
      <BetDetailData
        selectedBet={selectedBet}
        handleClose={() => setSelectedBet(null)}
      />
      <TableContainer sx={{ height: "100%", width: "100%" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 300 }}>
          <TableHead
            sx={{
              "& .MuiTableCell-head": {
                bgcolor: "background.paper",
                borderWidth: "1px 0px",
                borderStyle: "solid",
                borderColor: "#FFFFFF66",
              },
            }}
          >
            <TableRow sx={{ mt: 1 }}>
              <TableCell sx={{ pl: 3 }}>Date</TableCell>
              <TableCell>Game</TableCell>
              <TableCell sx={{ textAlign: "end" }}>Bet</TableCell>
              <TableCell sx={{ textAlign: "end", pr: 3 }}>Win</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedBets).map((key) => {
              let bet_amount = groupedBets[key].reduce(
                (r, c) => r + c.bet_amount || 0,
                0
              );
              let win_amount = groupedBets[key].reduce(
                (r, c) => r + c.profit || 0,
                0
              );
              return (
                <>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-body": {
                        fontSize: 14,
                        py: 0,
                        fontWeight: 500,
                        lineHeight: "16.94px",
                        // bgcolor:"background.neutral",
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          mt: 1.5,
                          bgcolor: "background.neutral",
                          py: 1,
                          pl: 3,
                        }}
                      >
                        {key}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{ mt: 1.5, bgcolor: "background.neutral", py: 1 }}
                      >
                        &nbsp;
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "end" }}>
                      <Box
                        sx={{ mt: 1.5, bgcolor: "background.neutral", py: 1 }}
                      >
                        {bet_amount}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "end" }}>
                      <Box
                        sx={{
                          mt: 1.5,
                          bgcolor: "background.neutral",
                          py: 1,
                          pr: 3,
                        }}
                      >
                        {win_amount}
                      </Box>
                    </TableCell>
                  </TableRow>
                  {groupedBets[key].map((_rounds) => {
                    // let bet_amount = _rounds.bet_list.reduce(
                    //   (r, c) => r + c.bet_amount || 0,
                    //   0
                    // );
                    // let win_amount = _rounds.bet_list.reduce(
                    //   (r, c) => r + c.win_amount || 0,
                    //   0
                    // );
                    return (
                      <TableRow onClick={() => setSelectedBet(_rounds)}>
                        <TableCell sx={{ pl: 3 }}>
                          {moment.unix(_rounds.end_time).format("HH:mm:ss")}
                        </TableCell>
                        <TableCell>Roulette</TableCell>
                        <TableCell sx={{ textAlign: "end" }}>
                          {_rounds.bet_amount}
                        </TableCell>
                        <TableCell sx={{ textAlign: "end", pr: 3 }}>
                          {_rounds.profit}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const BetDetailData = ({
  selectedBet,
  handleClose = () => {},
  anchor = "left",
}) => {
  return (
    <Drawer
      anchor={anchor}
      open={Boolean(selectedBet)}
      onClose={handleClose}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: "transparent",
            backdropFilter: "none",
          },
        },
      }}
    >
      <Box
        sx={{
          width: "75vw",
          height: "100vh",
          // px:3,
          overflow: "hidden",
        }}
      >
        <Stack direction={"column"} height={"100%"}>
          <Box
            sx={{
              pb: "16px",
              pt: "30px",
              px: 3,
            }}
          >
            <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
              <Box
                sx={{
                  width: 36,
                  height: 36,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 28,
                    height: 28,
                    bgcolor: "primary.main",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // position:"absolute",
                    // top:35,
                    // right:16
                  }}
                  onClick={handleClose}
                >
                  <CustomIcon
                    sx={{ bgcolor: "#000", width: 12, height: 12 }}
                    icon={"arrow_left"}
                  />
                </Box>
              </Box>
              <Typography
                flex={1}
                color={"text.secondary"}
                textAlign={"left"}
                variant="h5"
              >
                {"Player Bet History"}
              </Typography>
            </Stack>
          </Box>
          <Divider sx={{ mx: 3 }} />
          <CustomScroller width={"100%"}>
            <Box py={2}>
              <Stack direction={"column"} rowGap={2}>
                <Stack direction={"row"} spacing={0.5} sx={{ px: 3 }}>
                  <Stack direction={"column"} alignItems={"start"} spacing={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      Round ID:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      Game Start:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      Game End:
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} alignItems={"start"} spacing={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {selectedBet?.round_id}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {moment
                        .unix(selectedBet?.start_time)
                        .format("DD/MM/YYYY hh:mm:ss A")}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {moment
                        .unix(selectedBet?.end_time)
                        .format("DD/MM/YYYY hh:mm:ss A")}
                    </Typography>
                  </Stack>
                </Stack>
                <GameResult winNumber={selectedBet?.result.win_number} />
                <BetTable bets={selectedBet?.bets} />
              </Stack>
            </Box>
          </CustomScroller>
        </Stack>
      </Box>
    </Drawer>
  );
};

const GameResult = ({ winNumber = 0 }) => {
  const colors = {
    "#1FB02A": {
      background: "#189A18",
      border: "1px solid rgba(36, 219, 36, 0.25)",
      boxShadow: "0px 0px 6px rgba(36, 219, 36, 0.3)",
    },
    "#FF2727": {
      background: "#FF3A29",
      border: "1px solid rgba(255, 58, 41, 0.25)",
      boxShadow: "0px 0px 6px rgba(255, 58, 41, 0.3)",
    },
    "#FFFFFF": {
      background: "#000000",
      border: "1px solid rgba(0, 0, 0, 0.25)",
      boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)",
    },
  };
  return (
    <Stack
      mt={1}
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      sx={{
        width: "100%",
        padding: 2,
        bgcolor: "background.neutral",
        // mx:-3,
        px: 3,
      }}
    >
      <Typography variant="body3" sx={{ fontWeight: 400 }}>
        Round Result
      </Typography>
      <Box
        sx={{
          width: 35,
          py: 0.5,
          borderRadius: 0.25,
          color: "white",
          ...colors[palette.numbers[winNumber]],
        }}
      >
        <Typography variant="body3">{winNumber}</Typography>
      </Box>
    </Stack>
  );
};

const BetTable = ({ bets = [] }) => {
  const getRunnerName = (_bet) => {
    // if (
    //   ["Straight Up", "Corner Bet", "Street Bet", "Split Bet"].includes(
    //     _bet.selection_name
    //   )
    // ) {
    //   return JSON.stringify(_bet.bet_numbers, null, 1);
    // }
    return _bet.selection_name;
  };

  return (
    <TableContainer
      sx={{
        width: "100%",
        // px:3,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>Bet Type</TableCell>
            <TableCell sx={{ textAlign: "end" }}>Bet</TableCell>
            <TableCell sx={{ textAlign: "end", pr: 3 }}>Win</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bets.map((_bet) => {
            return (
              <TableRow>
                <TableCell sx={{ pl: 3 }}>{getRunnerName(_bet)}</TableCell>
                <TableCell sx={{ textAlign: "end" }}>{_bet.stake}</TableCell>
                <TableCell sx={{ textAlign: "end", pr: 3 }}>
                  {_bet.result.outcome === "won"
                    ? _bet.profit - _bet.liability
                    : 0}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>Total</TableCell>
            <TableCell sx={{ textAlign: "end" }}>
              {bets.reduce((r, c) => r + c.stake || 0, 0)}
            </TableCell>
            <TableCell sx={{ textAlign: "end", pr: 3 }}>
              {bets.reduce(
                (r, c) =>
                  r +
                    (c.result.outcome === "won" ? c.profit - c.liability : 0) ||
                  0,
                0
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};
