import {
  Box,
  LinearProgress,
  makeStyles,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomScroller from "../mini-components/CustomScroller";
import { GameDataContext } from "../services/GameCommunication";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { wheelNumbers } from "../constants/constant";
import palette from "./../theme/palette";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export default function Statistics() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { gameHistory } = useContext(GameDataContext);
  useEffect(() => {
    // console.log(",jghdfkjg")
    window.parent.postMessage({ type: "GET_STATS" });
  }, []);
  const tabs = [
    {
      name: "Results",
      component: <Results numbers={gameHistory?.numbers} />,
    },
    {
      name: "Advanced Metrics",
      component: <Metrics percentages={gameHistory?.percentages} />,
    },
    {
      name: "Hot & Cold",
      component: (
        <HotCold
          hotNumbers={gameHistory?.hotNumbers}
          coldNumbers={gameHistory?.coldNumbers}
          allCounts={gameHistory?.allCounts}
        />
      ),
    },
  ];
  return (
    <Box sx={{ overflow: "auto", flex: 1 }}>
      <Stack pt={3} direction={"column"} spacing={2} height={"100%"}>
        <Typography textAlign={"left"}>Last 100 Rounds</Typography>
        <Tabs
          value={selectedTab}
          onChange={(e, v) => setSelectedTab(v)}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons={true}
        >
          {tabs.map((e) => {
            return <Tab disableRipple label={e.name} />;
          })}
        </Tabs>
        <CustomScroller width={"100%"}>
          <Box
            sx={{
              pb: 5,
              pt: "4px",
            }}
          >
            {tabs[selectedTab].component}
          </Box>
        </CustomScroller>
      </Stack>
    </Box>
  );
}

const Results = ({ numbers = [] }) => {
  const getWinColor = (number) => {
    let colors = [
      "#1FB02A",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
      "#FFFFFF",
      "#FF0000",
    ];

    return colors[number];
  };

  return (
    <Stack direction={"row"} rowGap={"5px"} columnGap={"5px"} flexWrap={"wrap"}>
      {numbers.map((e) => {
        return (
          <Box
            sx={{
              maxWidth: 35,
              minWidth: 35,
              border: "1px solid rgba(255, 182, 32, 0.25)",
              boxShadow: "0px 0px 6px rgba(219, 188, 159, 0.3)",
              borderRadius: "2px",
              px: 1,
              py: 0.5,
            }}
          >
            <Typography variant="body3" color={getWinColor(e)}>
              {e}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};

const Metrics = ({ percentages }) => {
  useEffect(() => {
    console.log(percentages);
  }, [percentages]);
  return (
    <Stack direction={"column"} rowGap={"15px"}>
      <PercentageCard
        data={[{ title: "0 (Zero)", value: percentages?.["0"] }]}
      />
      <Stack direction={"row"} columnGap={"15px"} rowGap={"15px"}>
        <PercentageCard
          data={[
            { title: "1st Dozen", value: percentages?.["1st 12"] },
            { title: "2nd Dozen", value: percentages?.["2nd 12"] },
            { title: "3rd Dozen", value: percentages?.["3rd 12"] },
          ]}
        />
        <PercentageCard
          data={[
            { title: "1st Column", value: percentages?.["1st Column"] },
            { title: "2nd Column", value: percentages?.["2nd Column"] },
            { title: "3rd Column", value: percentages?.["3rd Column"] },
          ]}
        />
      </Stack>
      <Stack direction={"row"} columnGap={"15px"} rowGap={"15px"}>
        <PercentageCard
          data={[
            { title: "1-18", value: percentages?.["1 To 18"] },
            { title: "19-36", value: percentages?.["19 To 36"] },
          ]}
        />
        <PercentageCard
          data={[
            { title: "Red", value: percentages?.["Red"] },
            { title: "Black", value: percentages?.["Black"] },
          ]}
        />
      </Stack>
      <Stack direction={"row"} columnGap={"15px"} rowGap={"15px"}>
        <PercentageCard
          data={[
            { title: "Odd", value: percentages?.["Odd"] },
            { title: "Even", value: percentages?.["Even"] },
          ]}
        />
        <PercentageCard
          data={[
            { title: "Tiers", value: percentages?.race_track_tier },
            { title: "Voisins", value: percentages?.race_track_voisins },
            { title: "Orphelins", value: percentages?.race_track_orphelins },
            { title: "Zero", value: percentages?.race_track_zero },
          ]}
        />
      </Stack>
    </Stack>
  );
};

const PercentageCard = ({ data = [] }) => {
  return (
    <Box
      sx={{
        border: "1px solid rgba(255, 255, 255, 0.4)",
        borderRadius: "6px",
        px: "18px",
        py: 1.5,
        flex: 1,
      }}
    >
      <Stack direction={"column"} spacing={2}>
        {data.map((e, i) => {
          return (
            <Stack direction={"column"} rowGap={0.25} alignItems={"start"}>
              <Typography variant="subtitle1" fontWeight={500}>
                {e.title}
              </Typography>
              <Typography variant="subtitle1" fontWeight={500}>
                {e.value}%
              </Typography>
              <LinearProgress
                sx={{
                  width: "100%",
                  height: 4,
                  color: "#FFB200",
                  bgcolor: "#4D4D4D",
                  mt: 0.25,
                }}
                value={e.value}
                variant={"determinate"}
              />
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

const HotCold = ({ hotNumbers = [], coldNumbers = [], allCounts = [] }) => {
  const [data, setData] = useState({ labels: [], datasets: [] });
  // const data = {
  //     labels: [],
  //     datasets: [{
  //       label: 'frequncy',
  //       data: [2, 4, 2, 2, 2, 2, 5, 2, 6, 5, 5, 1, 2, 2, 3, 3, 2, 5, 4, 2, 6, 3, 1, 3, 1, 1, 5, 1, 2, 3, 3, 3, 2, 2, 1, 0, 0],
  //       backgroundColor: [
  //         'rgb(255, 0, 0)',
  //         'rgb(0, 0, 255)',
  //         'rgb(255, 255, 255)',
  //       ]
  //     }]
  //   };

  const getBgColor = (index) => {
    if (hotNumbers.includes(index)) {
      return "rgb(255, 0, 0)";
    } else if (coldNumbers.includes(index)) {
      return "rgb(0, 0, 255)";
    }
    return "rgb(66, 66, 66)";
  };

  useEffect(() => {
    setData({
      labels: [],
      datasets: [
        {
          label: "frequncy",
          data: [...Array(37)].map((e, i) => allCounts[wheelNumbers[i]?.value]),
          backgroundColor: [...Array(37)].map((e, i) =>
            getBgColor(wheelNumbers[i]?.value)
          ),
        },
      ],
    });
  }, [hotNumbers, coldNumbers, allCounts]);
  return (
    <Stack direction={"column"} justifyContent={"center"} spacing={4}>
      <Stack direction={"column"} spacing={1.5} justifyContent={"center"}>
        <Stack
          direction={"row"}
          spacing={0.5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              border: "1px solid #FF3A29",
              borderRadius: "3px",
              padding: "6px 12px",
              minWidth: 40,
              maxWidth: 40,
            }}
          >
            <Box component={"img"} src={"uiAssets/images/fire.svg"} />
          </Box>
          {hotNumbers.map((e) => {
            return (
              <Box
                sx={{
                  border: "1px solid #FF3A29",
                  borderRadius: "3px",
                  padding: "6px 12px",
                  minWidth: 40,
                  maxWidth: 40,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: palette.numbers[e] }}
                >
                  {e}
                </Typography>
              </Box>
            );
          })}
        </Stack>
        <Stack
          direction={"row"}
          spacing={0.5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              border: "1px solid #4339F2",
              borderRadius: "3px",
              padding: "6px 12px",
              minWidth: 40,
              maxWidth: 40,
            }}
          >
            <Box component={"img"} src={"uiAssets/images/snow.svg"} />
          </Box>
          {coldNumbers.map((e) => {
            return (
              <Box
                sx={{
                  border: "1px solid #4339F2",
                  borderRadius: "3px",
                  padding: "6px 12px",
                  minWidth: 40,
                  maxWidth: 40,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: palette.numbers[e] }}
                >
                  {e}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Stack>
      <Stack direction={"row"} width={"100%"} justifyContent={"center"}>
        <Box
          sx={{
            // bgcolor:"white"
            borderRadius: "100%",
            border: "1px solid #333333",
            position: "relative",
            width: 238,
            height: 238,
            p: 2.5,
          }}
        >
          <PolarArea
            data={data}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "100%",
              border: "1px solid #333333",
            }}
            options={{
              animation: {
                animateRotate: false,
                animateScale: true,
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              layout: {
                padding: 0,
              },
              scales: {
                r: {
                  ticks: {
                    display: false,
                  },
                  grid: {
                    display: false,
                    color: "red",
                  },
                  min: -0.8,
                },
              },
              elements: {
                arc: {
                  borderWidth: 0,
                  // borderColor:"grey",
                },
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
            }}
          >
            <CircleDivider width={236} height={236} />
          </Box>
          <Box
            sx={{
              bgcolor: "#999999",
              width: 22,
              height: 22,
              position: "absolute",
              borderRadius: "100%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
const CircleDivider = ({ width, height }) => {
  const radius = Math.min(width, height) / 2;
  const cx = width / 2;
  const cy = height / 2;
  const segments = 37;
  const anglePerSegment = 360 / segments;

  let lines = [];
  let texts = [];

  for (let i = 0; i < segments; i++) {
    const angle = (i * anglePerSegment * Math.PI) / 180;
    const x1 = cx + Math.sin(angle) * (radius / 2);
    const y1 = cy - Math.cos(angle) * (radius / 2);
    const x2 = cx + Math.sin(angle) * radius;
    const y2 = cy - Math.cos(angle) * radius;
    lines.push(
      <line key={i} x1={cx} y1={cy} x2={x2} y2={y2} stroke="#333333" />
    );
    const textX = cx + Math.sin(angle) * (radius * 0.91);
    const textY = cy - Math.cos(angle) * (radius * 0.91);
    const transform = `translate(-50%, -50%) rotate(${anglePerSegment * i}deg)`;
    texts.push(
      <Typography
        key={i}
        variant="subtitle1"
        sx={{
          position: "absolute",
          left: textX,
          top: textY,
          transformOrigin: "center",
          transform,
          color: wheelNumbers[i]?.color,
        }}
      >
        {wheelNumbers[i]?.value}
      </Typography>
    );
  }

  return (
    <>
      <svg width={width} height={height}>
        {lines}
      </svg>
      <Box
        sx={{
          transform: "rotate(5deg)",
          position: "absolute",
          top: 0,
          transformOrigin: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {texts}
      </Box>
    </>
  );
};
